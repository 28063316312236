import React from "react"

import Layout from "../components/layout";
import Image from "../components/image";

const IndexPage = () => (
  <Layout>
  <div
  style={{ maxWidth: `887px`, paddingTop: '100px' }}
  >
    <Image />
  </div>
  </Layout>
)

export default IndexPage
